import { render, staticRenderFns } from "./ZxFawuContractForm.vue?vue&type=template&id=997a7a70&scoped=true&"
import script from "./ZxFawuContractForm.vue?vue&type=script&lang=js&"
export * from "./ZxFawuContractForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "997a7a70",
  null
  
)

export default component.exports