<template>
  <el-form :model="contractForm" :rules="rules" ref="form" label-width="100px" class="contractForm" size="small">
    <el-row>
      <el-col :span="24">
        <el-form-item label="合同编号" prop="contract" :rules="{ required: true, message: '请输入合同编号', trigger: 'blur' }">
          <el-select v-model="contractForm.contract" filterable remote placeholder="合同编号" :remote-method="remoteMethod" :loading="loading">
            <el-option v-for="item in XiangmuGongdiOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="欠款金额" prop="qiankuan_amount">
          <el-input type="number" step="0.01" v-model="contractForm.qiankuan_amount"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="未返还材料情况" prop="notreturnmat">
          <el-input type="textarea" :rows="4" v-model="contractForm.notreturnmat"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item>
      <div style="float:right;">
        <el-button @click="resetForm()"> 取消 </el-button>
        <el-button type="primary" @click="submitForm()"> 确认添加 </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      contractForm: {},
      XiangmuGongdiOptions: [],
      rules: {
        qiankuan_amount: [{ required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/ }],
        notreturnmat: [{ required: true, message: '请填写施工材料', trigger: 'blur' }]
      }
    }
  },
  methods: {
    //项目工地搜索框响应事件，从后台查询10条记录
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          const api = '/getXiangmuGongdis/'
          this.$axios
            .get(api, {
              params: {
                query: query
              }
            })
            .then(res => {
              if (res.data.code === 1000) {
                this.XiangmuGongdiOptions = res.data.data.map(item => {
                  item.label = item.contract_id + '#' + item.project
                  item.value = item.id
                  return item
                })
              } else {
                console.log('没有项目工地信息！')
              }

              console.log('XiangmuGongdiOptions', this.XiangmuGongdiOptions)
            })
        }, 200)
      } else {
        this.XiangmuGongdiOptions = []
      }
    },

    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.$emit('submitForm', this.contractForm)
          this.contractForm = {}
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs['form'].resetFields()
      this.$emit('resetForm')
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped></style>
